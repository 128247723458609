// UploadPage.js
import React from 'react';
import UploadComponent from './UploadComponent';
import DeleteFileComponent from './DeleteFileComponent';

const UploadPage = () => {
    return (
        <div className="container my-5">
            <h1 className="text-center mb-5">Upload Page</h1>
            <UploadComponent />

            <div className="mt-5">
                <DeleteFileComponent />
            </div>
        </div>
    );
};

export default UploadPage;
