import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UploadPage from './UploadPage';
import AudioPlayer from './AudioPlayer';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/upload" element={<UploadPage />} />
                <Route path="*" element={<AudioPlayer />} />
            </Routes>
        </Router>
    );
}

export default App;
