import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const SyncAudioPlayer = ({ id, audioFiles, isActive, onActivate }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [gainNodes, setGainNodes] = useState([]);
  const [muteStatus, setMuteStatus] = useState(audioFiles.map(() => false)); // Track mute status for each voice

  const audioContext = useRef(null);  // Defer AudioContext creation
  const audioRefs = useRef([]);
  const gainRef = useRef([]);
  const isInitialized = useRef(false); // Track if audio is initialized

  // Initialize audio elements and gain nodes when component becomes active
  const initializeAudio = () => {
    if (!isInitialized.current && isActive) {
      console.log("Initializing AudioContext"); // Add this log
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      console.log("AudioContext state:", audioContext.current.state); // Add this line
  
      audioRefs.current = audioFiles.map((file, index) => {
        const audio = new Audio(file.src);
        audio.crossOrigin = "anonymous"; // Add this line
        audio.preload = 'auto';
  
        const source = audioContext.current.createMediaElementSource(audio);
        const gainNode = audioContext.current.createGain();
        gainNode.gain.value = muteStatus[index] ? 0 : 1;
  
        source.connect(gainNode).connect(audioContext.current.destination);
        gainRef.current[index] = gainNode;
  
        audio.onloadedmetadata = () => setDuration(audio.duration);
        audio.ontimeupdate = () => setCurrentTime(audio.currentTime);
  
        // Add `ended` event listener
        audio.addEventListener("ended", () => {
          const allEnded = audioRefs.current.every((a) => a.ended);
          if (allEnded) {
            setIsPlaying(false); // Stop the play/pause state
          }
        });
  
        return audio;
      });
  
      setGainNodes(gainRef.current);
      isInitialized.current = true;
    }
  };
  
  const togglePlayPause = async () => {
    if (!isPlaying) {
      try {
        // Ensure audio is initialized on first play
        initializeAudio();

        // Resume AudioContext if suspended
        if (audioContext.current.state === 'suspended') {
          await audioContext.current.resume();
        }

        // Play all audio elements without resetting their current time
        await Promise.all(audioRefs.current.map(audio => audio.play()));
        setIsPlaying(true);
      } catch (error) {
        console.error("Failed to play audio files in sync:", error);
      }
    } else {
      // Pause all audio elements
      audioRefs.current.forEach(audio => audio.pause());
      setIsPlaying(false);
    }
  };

  const handleMuteToggle = (index) => {
    // Initialize audio elements if needed to allow muting before playing
    initializeAudio();

    // Update mute status in state for immediate icon update
    setMuteStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = !newStatus[index];
      return newStatus;
    });

    // Apply mute directly on gain node
    if (gainNodes[index]) {
      gainNodes[index].gain.value = gainNodes[index].gain.value === 1 ? 0 : 1;
    }
  };

  const handleProgressChange = (e) => {
    const newTime = e.target.value;
    setCurrentTime(newTime);
    audioRefs.current.forEach(audio => (audio.currentTime = newTime));
  };

  // Handle activation of the component
  const handleComponentClick = () => {
    if (!isActive) {
      console.log("Component clicked, activating component:", id); // Add this log
      onActivate(id); // Activate this component if not active
      initializeAudio(); // Initialize audio once upon activation
    }
  };

  return (
    <div
      className={`card p-3 ${isActive ? 'border-primary' : 'border-secondary'}`}
      style={{ borderRadius: '15px', maxWidth: '500px', margin: '20px auto', cursor: 'pointer', borderWidth: '2px' }}
      onClick={handleComponentClick} // Activate component on tap
    >

      {/* Play/Pause Button */}
      <div className="d-flex justify-content-center mb-3">
        <button onClick={togglePlayPause} className="btn btn-primary btn-lg" disabled={!isActive}>
          <i className={`bi ${isPlaying ? 'bi-pause-fill' : 'bi-play-fill'}`}></i>
        </button>
      </div>

      {/* Progress Bar */}
      <input
        type="range"
        min="0"
        max={duration}
        value={currentTime}
        onChange={handleProgressChange}
        className="form-range"
        style={{ margin: '10px 0' }}
        disabled={!isActive}
      />

      {/* Voice Toggles */}
      <div className="d-flex flex-wrap justify-content-around">
        {audioFiles.map((file, index) => (
          <div key={index} className="d-flex align-items-center my-2">
            <span className="me-2">{file.voice}</span>
            <button
              onClick={() => handleMuteToggle(index)}
              className="btn btn-outline-primary"
              disabled={!isActive}
            >
              <i className={`bi ${muteStatus[index] ? 'bi-volume-mute-fill' : 'bi-volume-up-fill'}`}></i>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SyncAudioPlayer;
