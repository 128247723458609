// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "chor-brzaczowice.firebaseapp.com",
  projectId: "chor-brzaczowice",
  storageBucket: "chor-brzaczowice.firebasestorage.app",
  messagingSenderId: "3886540939",
  appId: "1:3886540939:web:406048dfce3d47b27f8c8e",
  measurementId: "G-N5CRHM6CX2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore and Storage
export const db = getFirestore(app);
export const storage = getStorage(app);